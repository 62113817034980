import axios from 'axios'
import qs from 'querystring'

export default class Prayer {
  static async fetch({ prayerApiBase, uuid, page, limit}) {
    const response = await axios.get(`${prayerApiBase}/churches/${uuid}/prayers?` + qs.stringify({ page, limit }))
    return response.data
  }

  static async acknowledge(link) {
    const response = await axios.get(link)
    return response.data
  }
}
