import { assign, first } from 'lodash'
import axios from 'axios'

function unwrapServiceRequestResponse(response) {
  const serviceRequest = first(response.data.service_requests)
  const serviceResponse = first(serviceRequest.service_responses)
  return serviceResponse.parsed_response
}

export default class Integration {
  constructor(integrationsBase, id) {
    this.integrationsBase = integrationsBase
    this.id = id
  }

  async makeServiceRequest(action, data) {
    const service_request = { action }
    if (data) assign(service_request, { data })
    const response = await axios.post(
      `${this.integrationsBase}/integrations/${this.id}/service_requests`,
      { service_request }
    )
    return unwrapServiceRequestResponse(response)
  }
}
