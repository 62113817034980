/* global intlTelInputUtils */

import React, { useEffect, useRef } from 'react'
import intlTelInput from '@cloversites/intl-tel-input'

import TextInput from './TextInput'

// This integration is based on this comment:
// https://github.com/jackocnr/intl-tel-input/issues/346#issuecomment-595404009

function createIntlTelInput(input) {
  const iti = intlTelInput(input, {
    utilsScript: '/intl-tel-input/js/utils.js',
    preferredCountries: ['US', 'CA', 'GB', 'NZ', 'AU'],
    nationalMode: false,
    formatOnDisplay: true
  })
  return iti
}

function formatIntlTelInput(iti) {
  if (typeof intlTelInputUtils === 'object') { // Utils are lazy loaded
    const currentText = iti.getNumber(intlTelInputUtils.numberFormat.E164)
    if (typeof currentText === 'string') { // Sometimes currentText is an object :)
      iti.setNumber(currentText) // Will autoformat because formatOnDisplay = true
    }
  }
}

export default function TelInput(props) {
  const { onBlur, onChange } = props
  const itiRef = useRef(null)
  const inputRef = useRef(null)

  useEffect(() => {
    itiRef.current = createIntlTelInput(inputRef.current)
  }, [])

  useEffect(() => {
    const input = inputRef.current
    input.addEventListener('countrychange', onChange)
    return () => {
      input.removeEventListener('countrychange', onChange)
    }
  }, [onChange])

  function handleBlur() {
    formatIntlTelInput(itiRef.current)
    if (onChange) onChange.apply(this, arguments)
    if (onBlur) return onBlur.apply(this, arguments)
  }

  return (
    <TextInput ref={inputRef} type="tel" {...props} onBlur={handleBlur} />
  )
}
