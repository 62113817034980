// Components for organizations and classes like those from (FMS) form sections.

import { omit } from 'lodash'

import React from 'react'
import classNames from 'classnames'

export function Row(props) {
  return <div {...props} className="row" />
}

export const columnSizes = ['lg', 'md', 'sm', 'xs']

export function column({ lg, md, sm, xs }) {
  return classNames(
    lg && `col-lg-${sm}`,
    md && `col-md-${sm}`,
    sm && `col-sm-${sm}`,
    xs && `col-xs-${xs}`
  )
}

export function Column(props) {
  const columnProps = omit(props, columnSizes)
  return <div {...columnProps} className={column(props)} />
}

export function Field(props) {
  return <div {...props} className="formbuilder-field" />
}

export function Label(props) {
  const labelProps = omit(props, columnSizes)
  return <label {...labelProps}
                className={classNames('control-label', column(props))} />
}

export function FormGroup(props) {
  const { valid, error } = props
  const groupProps = omit(props, 'valid', 'error')
  return <div {...groupProps} className={classNames('form-group', {
                'has-error': !valid && error
              })} />
}

export function InputGroup(props) {
  return <div {...props} className="input-group" />
}

export function InputGroupAddon(props) {
  return <div {...props} className="input-group-addon" />
}

export function HelpBlock(props) {
  const { valid, error } = props
  const spanProps = omit(props, 'valid', 'error')
  return <span {...spanProps} className={classNames('help-block', {
                 'has-error': !valid && error,
                 'error-msg': error
               })}>{error}</span>
}

export function EnvelopeIcon() {
  return <i className="fms-Envelope-Single" aria-hidden="true" />
}
