import { assign, map } from 'lodash'

export default function prayerListReducer(state, action) {
  switch (action.type) {
  case 'FETCH_PAGE': {
    const { data, meta } = action.parameter
    return { data, meta }
  }
  case 'ACKNOWLEDGE_PRAYER': {
    const { data } = state
    const acknowledgedPrayer = action.parameter
    return assign({}, state, {
      data: map(data, (prayer) =>
        prayer.data.uuid === acknowledgedPrayer.data.uuid ?
          acknowledgedPrayer :
          prayer
      )
    })
  }
  }
}
