function resolveAfter(time) {
  return new Promise(resolve => setTimeout(resolve, time))
}

// Ensure the value of `promise` isn’t returned sooner than `time` milliseconds.
export default async function minimumDelay(time, promise) {
  let value, error
  await Promise.all([
    promise.then(_value => { value = _value },
                 _error => { error = _error }),
    resolveAfter(time)
  ])
  if (error) throw error
  return value
}
