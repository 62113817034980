import classNames from 'classnames'
import React, { useState } from 'react'
import { useForm, useField } from 'react-form'

import minimumDelay from '../../../lib/minimumDelay'
import createPrayer from '../actions/createPrayer'

import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import { isUndefined } from 'lodash'

import {
  Row,
  Column,
  Field,
  Label,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  HelpBlock,
  EnvelopeIcon
} from '../../../lib/components/form'

import {
  TextInput,
  EmailInput,
  TelInput,
  CheckboxInput,
  SubmitButton,
  Textarea,
  Select
} from '../../../lib/components/inputs'

function getValidationProps({ error, isTouched }) {
  return {
    valid: isTouched && !error,
    error: isTouched && error
  }
}

function validateName(value) {
  if (!value) return 'A name is required'
  return false
}

function NameField({ id }) {
  const {
    meta,
    getInputProps
  } = useField('name', { defaultValue: '', validate: validateName })
  return (
    <Field>
      <FormGroup {...getValidationProps(meta)}>
        <Label htmlFor={`${id}__NameInput`} xs={12}>Name</Label>
        <Column sm={6} xs={12}>
          <TextInput {...getInputProps()} id={`${id}__NameInput`} required />
          <HelpBlock {...getValidationProps(meta)} />
        </Column>
      </FormGroup>
    </Field>
  )
}

function EmailNotificationsField() {
  const { getInputProps, value, setValue } = useField('email_updates', {
    defaultValue: false
  })
  function handleChange(e) {
    setValue(e.target.checked)
  }
  return (
    <Field>
      <Column xs={12}>
        <Row>
          <CheckboxInput
            {...getInputProps()}
            label="Receive email notifications" xs={12}
            checked={value}
            onChange={handleChange} />
        </Row>
      </Column>
    </Field>
  )
}

function validateEmail(value) {
  if (!value) return 'An email is required'
  if (!/.+?@.+?\..+/.test(value)) return 'A valid email is required'
  return false
}

function EmailField({ id }) {
  const {
    meta,
    getInputProps
  } = useField('email', { defaultValue: '', validate: validateEmail })
  return (
    <Field>
      <FormGroup {...getValidationProps(meta)}>
        <Label htmlFor={`${id}__EmailInput`} xs={12}>Email</Label>
        <Column sm={6} xs={12}>
          <InputGroup>
            <InputGroupAddon><EnvelopeIcon /></InputGroupAddon>
            <EmailInput {...getInputProps()} id={`${id}__EmailInput`} required />
          </InputGroup>
          <HelpBlock {...getValidationProps(meta)} />
        </Column>
      </FormGroup>
    </Field>
  )
}

function PhoneField({ id }) {
  const {
    meta,
    getInputProps
  } = useField('phone', { defaultValue: '' })
  return (
    <Field>
      <FormGroup {...getValidationProps(meta)}>
        <Label htmlFor={`${id}__PhoneInput`} xs={12}>Phone</Label>
        <Column sm={6} xs={12}>
          <TelInput {...getInputProps()} id={`${id}__PhoneInput`} />
          <HelpBlock {...getValidationProps(meta)} />
        </Column>
      </FormGroup>
    </Field>
  )
}

function validatePrayerRequest(value) {
  if (!value) return 'A prayer request is required'
  if (/god ?d?am[mn]? ?it/i.test(value)) return 'Don’t take the Lord’s name in vain'
  return false
}

function PrayerRequestField({ id }) {
  const {
    meta,
    getInputProps
  } = useField('prayer', { defaultValue: '', validate: validatePrayerRequest })
  return (
    <Field>
      <FormGroup {...getValidationProps(meta)}>
        <Label htmlFor={`${id}__PrayerRequestInput`} xs={12}>Prayer Request</Label>
        <Column xs={12}>
          <Textarea {...getInputProps()} id={`${id}__PrayerRequestInput`} required />
          <HelpBlock {...getValidationProps(meta)} />
        </Column>
      </FormGroup>
    </Field>
  )
}

function PublicVisibilityField({ id }) {
  const { getInputProps } = useField('syndication_type', {
    defaultValue: 'syndicate publicly'
  })
  return (
    <Field>
      <FormGroup>
        <Label htmlFor={`${id}__PublicVisibilityInput`} xs={12}>Public Visibility</Label>
        <Column sm={6} xs={12}>
          <Select {...getInputProps()} id={`${id}__PublicVisibilityInput`}>
            <option value="syndicate publicly">Make everything public</option>
            <option value="syndicate anonymously">Hide my name, email, & phone</option>
            <option value="syndicate info only">Hide my prayer request</option>
            <option value="unsyndicated">Make everything private</option>
          </Select>
        </Column>
      </FormGroup>
    </Field>
  )
}

function ChurchVisibilityField({ id }) {
  const { getInputProps } = useField('authorization_type', {
    defaultValue: 'authorized publicly'
  })
  return (
    <Field>
      <FormGroup>
        <Label htmlFor={`${id}__StaffVisibilityInput`} xs={12}>Staff Visibility</Label>
        <Column sm={6} xs={12}>
          <Select {...getInputProps()} id={`${id}__StaffVisibilityInput`}>
            <option value="authorized publicly">Let everyone know</option>
            <option value="authorized anonymously">Hide my name, email, & phone</option>
          </Select>
        </Column>
      </FormGroup>
    </Field>
  )
}

function SubmitField({ isSubmitting }) {
  return (
    <div>
      <SubmitButton disabled={isSubmitting}>
        {isSubmitting ? 'Submitting…' : 'Submit'}
      </SubmitButton>
    </div>
  )
}

function SubmissionMessage({ error, message }) {
  return (
    <p className={classNames('sites-PrayerForm__SubmissionMessage', {
         'has-error': error
       })}>
      {message}
    </p>
  )
}

function getSubmitValues(integrationsBase, integration) {
  return function submitValues(values) {
    return createPrayer(integrationsBase, integration, values)
  }
}

function getHandleSubmit(submitValues, setResult, executeRecaptcha) {
  return async function handleSubmit(values, instance) {
    setResult(null)
    try {
      const token = await executeRecaptcha('createprayer')

      if (!token) {
        setResult({ error: true, message: `Failed to verify you’re not a robot` })
        return
      }

      values = { ...values, token }

      // Add a minimum delay so “Submitting…” will be visible for a moment.
      await minimumDelay(300, submitValues(values))
      setResult({ message: 'Submitted prayer for review.  Thank you.' })
      instance.reset()
    } catch (e) {
      setResult({ error: true, message: 'Error submitting prayer' })
    }
  }
}

function showPhoneField(settings) {
  return isUndefined(settings.show_phone_field) ||
    settings.show_phone_field
}

function PrayerFormComponent(props) {
  const { id, integrationsBase, integration, settings, recaptchaKey } = props
  const fieldProps = { id: `PrayerForm-${id}` }
  const [result, setResult] = useState()
  const submitValues = getSubmitValues(integrationsBase, integration)
  const { executeRecaptcha } = useGoogleReCaptcha()
  const handleSubmit = getHandleSubmit(submitValues, setResult, executeRecaptcha)
  const {
    Form,
    meta: { isSubmitting }
  } = useForm({ onSubmit: handleSubmit })

  return (
    <Form noValidate>
      <NameField {...fieldProps} />
      <EmailField {...fieldProps} />
      <EmailNotificationsField />
      { showPhoneField(settings) && <PhoneField {...fieldProps} />}
      <PrayerRequestField {...fieldProps} />
      <PublicVisibilityField {...fieldProps} />
      <ChurchVisibilityField {...fieldProps} />
      <SubmitField isSubmitting={isSubmitting} />
      { result && <SubmissionMessage {...result} /> }
    </Form>
  )
}

export default function PrayerForm(props) {
  const { recaptchaKey } = props
  return (
    <GoogleReCaptchaProvider reCaptchaKey={recaptchaKey}>
      <PrayerFormComponent {...props} />
    </GoogleReCaptchaProvider>
  )
}
