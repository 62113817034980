// Components for inputs like those from (FMS) form sections.

import { omit } from 'lodash'

import React from 'react'
import classNames from 'classnames'

import { columnSizes, column } from './form'
import TextInput from './TextInput'

export { TextInput as TextInput }

export function EmailInput(props) {
  return <TextInput type="email" {...props} />
}

export { default as TelInput } from './TelInput'

export function CheckboxInput(props) {
  const { label } = props
  const checkboxProps = omit(props, 'label', ...columnSizes)
  return (
    <div className={classNames('checkbox', column(props))}>
      <label>
        <input type="checkbox" {...checkboxProps} />
        <span>{label}</span>
        <span className="helper" />
      </label>
    </div>
  )
}

export function SubmitButton(props) {
  return <button type="submit" {...props} className="sites-button" />
}

export function Textarea(props) {
  return <textarea {...props} className="form-control" />
}

export function Select(props) {
  return (
    <div className="dropdown-container">
      <select {...props} className="form-control" />
      <div className="dropdown-caret" />
    </div>
  )
}
